import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "../components/ProductsPageComponent/MenuContent/MenuPdf";

const Menu = () => {
  const categoryData = [
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2Fsweets%201.svg?alt=media&token=273e2384-3b9d-424f-8d01-d2b639fd6fd9",
      categoryName: "cat3",
      products: [
        {
          name: "water",
          cost: 1.6,
        },
        {
          name: "sklfjdlskfjskfjlaksdjklsjfdklsjfdkljslkf",
          cost: 15,
        },
        {
          name: "teting 2 update",
          cost: 10,
        },
        {
          name: "no img",
          cost: 10,
        },
      ],
    },
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2FGroup%201000002795.svg?alt=media&token=9934e492-3f6c-4c27-9e5f-4caa346af9c5",
      categoryName: "testing  api",
      products: [
        {
          name: "testing account",
          cost: 10,
        },
      ],
    },
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2Fcoffee-cup%202.svg?alt=media&token=01b3e7f3-a10d-4255-81cb-7b0fc5496247",
      categoryName: "cat2",
      products: [
        {
          name: "png test",
          cost: 10,
        },
        {
          name: "svg test",
          cost: 12,
        },
        {
          name: "testing apk",
          cost: 10,
        },
        {
          name: "hi my name is amit anand. i am a software developer",
          cost: 10,
        },
        {
          name: "prod2",
          cost: 2.1,
        },
      ],
    },
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2Fsnack%201.svg?alt=media&token=aaad078a-df41-4e83-b29e-e99418d5a2b2",
      categoryName: "cat5",
      products: [
        {
          name: "tesing updated",
          cost: 10,
        },
      ],
    },
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2FGroup.svg?alt=media&token=f1022f67-356d-4dc2-bc8b-b7cb5611b872",
      categoryName: "Cat1",
      products: [
        {
          name: "soda",
          cost: 1.1,
        },
        {
          name: "png test 2",
          cost: 12,
        },
      ],
    },
    {
      categoryImage:
        "https://firebasestorage.googleapis.com/v0/b/camp-pos.appspot.com/o/categories%2Fice-cream%20(1)%201.svg?alt=media&token=509ef065-fbdd-4a00-b69a-86654fc3d01f",
      categoryName: "ice creaam",
      products: [
        {
          name: "vanilla",
          cost: 100,
        },
      ],
    },
  ];
  return (
    <div className="w-full h-[100vh]">
      <PDFViewer width="100%" height="100%">
        <MyDocument
          categoryData={categoryData}
          title={"amit"}
          subTitle={"Anand"}
        />
      </PDFViewer>
    </div>
  );
};

export default Menu;
