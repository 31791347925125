import React, { useState, useContext } from "react";
import { AuthContext } from "../../../auth/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import Cross from "../../../assets/Cross.svg";


function BunksSetting() {
  const { user, token, setUser } = useContext(AuthContext);
  const [bunks, setBunks] = useState(user.bunks || []);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedBunk, setSelectedBunk] = useState(null);

  const Bunk = ({ value }) => {
    return (
      <button
        className="d-flex justify-center text-chocolate-700 items-center w-12 h-12 rounded"
        style={{ border: "1px solid #DDDDDD" }}
        onClick={() => handleShowEditModal(value)}
      >
        <span className="text-xs font-medium">{value}</span>
      </button>
    );
  };

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);

  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const handleShowRemoveModal = () => setShowRemoveModal(true);

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedBunk("");
  };
  const handleShowEditModal = (bunkNumber) => {
    setShowEditModal(true);
    setSelectedBunk(bunkNumber);
  };

  const AddBunkModal = (props) => {
    const [bunkNumber, setBunkNumber] = useState("");

    const handleAddBunk = async () => {
      if (bunkNumber !== "") {
        try {
          toast.loading("Adding bunk...");
          const payload = { userId: user.id, bunkNumber };
          const res = await axios.post(
            `${process.env.REACT_APP_HOST_URL}/api/pos/setting/addBunk`,
            payload,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (res && res.data && res.data.success) {
            toast.dismiss();
            toast.success("Bunk Added Successfully");
            const updatedUser = {
              ...user,
              bunks: user.bunks
                ? [...user.bunks, { bunkNumber }]
                : [{ bunkNumber }],
            };
            setUser(updatedUser);
            setBunks(updatedUser.bunks);
            setBunkNumber("");
          } else {
            toast.dismiss();
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.dismiss();
          toast.error(error.response?.data?.message || "Failed to remove bunk");
        } finally {
          props.handleClose();
        }
      }
    };
    const handleSwitch = () => {
      props.handleClose();
      props.handleShowRemove();
    };
    return (
      <>
        <Modal
          show={props.show}
          onHide={props.handleClose}
          dialogClassName="custom-modal"
          centered
        >
          <div className="d-flex flex-column w-[420px] h-[200px]">
            <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "21px",
                  lineHeight: "31.5px",
                  marginLeft: "33px",
                }}
              >
                Add Bunk
              </div>
              <button
                className="w-[15.5px] h-[15.5px] me-[23px]"
                onClick={props.handleClose}
              >
                <img src={Cross} alt="cross" />
              </button>
            </div>
            <div className="d-flex justify-center items-center w-100 h-[40px] mt-[15px]">
              <div
                className="d-flex items-center justify-center w-[230px] h-[40px] "
                style={{ background: "#f8f8f8", borderRadius: "9px" }}
              >
                <input
                  placeholder="Bunk"
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#f8f8f8",
                    paddingLeft: "20px",
                  }}
                  className="custom-placeholder"
                  onChange={(e) => setBunkNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-center items-center h-[30px] mt-[19px]">
              <button
                className="h-[30px] w-[94px]"
                style={{
                  backgroundColor: "#FF8943",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                onClick={() => handleAddBunk()}
              >
                Add
              </button>
            </div>
            <div className="d-flex justify-center h-[20px] mt-[14px]">
              <button
                onClick={handleSwitch}
                className="h-[20px]"
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "rgba(0, 0, 0, 0.28)",
                }}
              >
                Remove Bunk
              </button>
            </div>
          </div>
        </Modal>
        <style>{`
          .custom-modal .modal-content {
            border-radius: 20px;
            width: 420px;
          }

          .custom-placeholder::placeholder {
            color: red;
            font-size: 14px;
            font-weight: 400;
            line-height: 22.5px;
            color: rgba(0, 0, 0, 0.28);
          }
        `}</style>
      </>
    );
  };

  const RemoveBunkModal = (props) => {
    const [bunkNumber, setBunkNumber] = useState("");

    const handleRemoveBunk = async (value) => {
      if (value !== "") {
        try {
          toast.loading("Removing bunk...");
          const payload = { userId: user.id, bunkNumber: value };
          const res = await axios.post(
            `${process.env.REACT_APP_HOST_URL}/api/pos/setting/removeBunk`,
            payload,
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (res.data.success) {
            toast.dismiss();
            toast.success("Bunk Removed Successfully");
            const newBunks = bunks.filter((bunk) => {
              return bunk.bunkNumber !== value;
            });
            const updatedUser = {
              ...user,
              bunks: newBunks,
            };
            setUser(updatedUser);
            setBunks(newBunks);
            setBunkNumber("");
          } else {
            toast.dismiss();
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.dismiss();
          toast.error(error.response?.data?.message || "Failed to remove bunk");
        } finally {
          props.handleClose();
        }
      }
    };
    const handleSwitch = () => {
      props.handleClose();
      props.handleShowAdd();
    };
    return (
      <>
        <Modal
          show={props.show}
          onHide={props.handleClose}
          dialogClassName="custom-modal"
          centered
        >
          <div className="d-flex flex-column w-[420px] h-[200px]">
            <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "21px",
                  lineHeight: "31.5px",
                  marginLeft: "33px",
                }}
              >
                Remove Bunk
              </div>
              <button
                className="w-[15.5px] h-[15.5px] me-[23px]"
                onClick={props.handleClose}
              >
                <img src={Cross} alt="cross" />
              </button>
            </div>
            <div className="d-flex justify-center items-center w-100 h-[40px] mt-[15px]">
              <div
                className="d-flex items-center justify-center w-[230px] h-[40px] "
                style={{ background: "#f8f8f8", borderRadius: "9px" }}
              >
                <input
                  placeholder="Bunk"
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#f8f8f8",
                    paddingLeft: "20px",
                  }}
                  className="custom-placeholder"
                  onChange={(e) => setBunkNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-center items-center h-[30px] mt-[19px]">
              <button
                className="h-[30px] w-[94px]"
                style={{
                  backgroundColor: "#FF8943",
                  borderRadius: "5px",
                  color: "#fff",
                }}
                onClick={() => handleRemoveBunk(bunkNumber)}
              >
                Remove
              </button>
            </div>
            <div className="d-flex justify-center h-[20px] mt-[14px]">
              <button
                onClick={handleSwitch}
                className="h-[20px]"
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "rgba(0, 0, 0, 0.28)",
                }}
              >
                Add Bunk
              </button>
            </div>
          </div>
        </Modal>
        <style>{`
          .custom-modal .modal-content {
            border-radius: 20px;
            width: 420px;
          }

          .custom-placeholder::placeholder {
            color: red;
            font-size: 14px;
            font-weight: 400;
            line-height: 22.5px;
            color: rgba(0, 0, 0, 0.28);
          }
        `}</style>
      </>
    );
  };

  const EditBunkModal = (props) => {
    const [bunkNumber, setBunkNumber] = useState(props.selectedBunk);
    return (
      <>
        <Modal
          show={props.show}
          onHide={props.handleClose}
          dialogClassName="custom-modal"
          centered
        >
          <div className="d-flex flex-column w-[420px] h-[200px]">
            <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "21px",
                  lineHeight: "31.5px",
                  marginLeft: "33px",
                }}
              >
                Edit Bunk
              </div>
              <button
                className="w-[15.5px] h-[15.5px] me-[23px]"
                onClick={props.handleClose}
              >
                <img src={Cross} alt="cross" />
              </button>
            </div>
            <div className="d-flex justify-center items-center w-100 h-[40px] mt-[20px]">
              <div
                className="d-flex items-center justify-center w-[230px] h-[40px] "
                style={{ background: "#f8f8f8", borderRadius: "9px" }}
              >
                <input
                  placeholder="Bunk"
                  value={bunkNumber}
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#f8f8f8",
                    paddingLeft: "20px",
                  }}
                  className="custom-placeholder"
                  // onChange={(e) => setBunkNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex justify-center items-center h-[30px] mt-[25px] gap-3">
              <button 
               className="h-[30px] w-[94px]"
               style={{
                 backgroundColor: "#E0E0E0",
                 borderRadius: "5px",
                 color: "",
               }}>
                 Delete
              </button>
              <button
                className="h-[30px] w-[94px]"
                style={{
                  backgroundColor: "#FF8943",
                  borderRadius: "5px",
                  color: "#fff",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
        <style>{`
        .custom-modal .modal-content {
          border-radius: 20px;
          width: 420px;
        }

        .custom-placeholder::placeholder {
          color: red;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.5px;
          color: rgba(0, 0, 0, 0.28);
        }
      `}</style>
      </>
    );
  };

  return (
    <>
      <div className="px-10 py-8">
        <div
          className="d-flex flex-wrap h-[80px] mt-2 "
          style={{ gap: "12px" }}
        >
          {bunks
            .sort((a, b) => parseInt(a.bunkNumber) - parseInt(b.bunkNumber))
            .map((item, index) => {
              return <Bunk key={index} value={item.bunkNumber} />;
            })}

          <button
            className="d-flex justify-center items-center w-12 h-12 text-white rounded"
            style={{
              border: "1px solid #DDDDDD",

              background: "linear-gradient(180deg, #EE8446 0%, #FF9F66 100%)",
            }}
            onClick={handleShowAddModal}
          >
            <span className="text-3xl">+</span>
          </button>
        </div>
      </div>

      <AddBunkModal
        show={showAddModal}
        setShow={setShowAddModal}
        handleClose={handleCloseAddModal}
        setBunks={setBunks}
        handleShowRemove={handleShowRemoveModal}
      />

      <RemoveBunkModal
        show={showRemoveModal}
        setShow={setShowRemoveModal}
        handleClose={handleCloseRemoveModal}
        setBunks={setBunks}
        handleShowAdd={handleShowAddModal}
      />

      <EditBunkModal
        show={showEditModal}
        setShow={setShowEditModal}
        handleClose={handleCloseEditModal}
        selectedBunk= {selectedBunk}
      />
    </>
  );
}

export default BunksSetting;
