import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import { Toaster } from "react-hot-toast";
import GlobalDndContext from "./components/DndContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalDndContext>
    <BrowserRouter>
    <React.StrictMode>
    <AuthProvider>
        <Toaster />
        <App />
      </AuthProvider>
    </React.StrictMode>
  </BrowserRouter>
  </GlobalDndContext>
  
);

reportWebVitals();
