import React, { useState } from 'react';
import SettingOptionChip from '../Common/settingOptionChip';
import PoweredBy from '../Common/PoweredBy';
import GeneralSettings from './rightLayout/generalSettings';
import BunksSetting from './rightLayout/bunksSetting';
import LoginInfo from './rightLayout/loginInfo';
import OnlinePaymentsSetting from './rightLayout/OnlinePaymentsSetting';

function Setting() {
  const [selectedOption, setSelectedOption] = useState('general');

  const options = [
    { id: 'general', name: 'General Settings' },
    { id: 'bunks', name: 'Bunks' },
    { id: 'loginInfo', name: 'Login info' },
    { id: 'OnlinePayments', name: 'Online Payments' },
  ];

  return (
    <div className='flex gap-6 w-100'>
      <div className='flex flex-col h-full bg-white w-[20%] rounded-lg px-4 py-4'>
        <div className='flex flex-col gap-2 flex-grow-1'>
          <div className='font-bold text-lg mb-2'>Settings</div>
          {options.map((option) => (
            <SettingOptionChip
              key={option.id}
              name={option.name}
              isSelected={selectedOption === option.id}
              onClick={() => setSelectedOption(option.id)}
            />
          ))}
        </div>
        <div>
          <PoweredBy />
        </div>
      </div>
      <div className='h-full rounded-lg bg-white w-full'> 
          {selectedOption === 'general' && <GeneralSettings />}
          {selectedOption === 'bunks' && <BunksSetting />}
          {selectedOption === 'loginInfo' && <LoginInfo />}
          {selectedOption === 'OnlinePayments' && <OnlinePaymentsSetting />}
      </div>
    </div>
  );
}

export default Setting;











// import React, { useState, useContext } from "react";
// import { Modal } from "react-bootstrap";
// import pinLock from "../assets/pinLock.svg";
// import { Switch } from "antd";
// import Cross from "../assets/Cross.svg";
// import { AuthContext } from "../auth/AuthProvider";
// import toast from "react-hot-toast";
// import { Spinner } from "@chakra-ui/react";
// import axios from "axios";
// import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import userIcon from "../assets/user.svg";
// import lock from "../assets/lock.svg";
// import Refresh from "../components/AccountsPage/RefreshButton";

// function Setting() {
//   const { user, token, setUser } = useContext(AuthContext);
//   const [bunks, setBunks] = useState(user.bunks || []);
//   const [showPin, setShowPin] = useState(false);
//   const [showAddModal, setShowAddModal] = useState(false);
//   const [pin, setPin] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [checkedPrint, setCheckedPrint] = useState(user.isAddBalanceEnabled);
//   const [posPassword, setPosPasword] = useState("");
//   const [showPosPassword, setShowPosPassword] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [cashCheckout, setCashCheckout] = useState(user?.isCashCheckoutEnabled);
//   const [loadingRefresh, setLoadingRefresh] = useState(false);

//   const handleChange = (e) => {
//     setPosPasword(e.target.value);
//   };

//   const handleEditPassword = async () => {
//     try {
//       setLoading(true);
//       const payload = { userId: user.id, password: posPassword };
//       const res = await axios.post(
//         `${process.env.REACT_APP_HOST_URL}/api/pos/setting/editPassword`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json;charset=UTF-8",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (res.data.success) {
//         toast.success("Password updated successfully");
//       } else {
//         toast.error(res.data.message);
//       }
//     } catch (error) {
//       toast.error("Failed to update password");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCloseAddModal = () => setShowAddModal(false);
//   const handleShowAddModal = () => setShowAddModal(true);

//   const [showRemoveModal, setShowRemoveModal] = useState(false);
//   const handleCloseRemoveModal = () => setShowRemoveModal(false);
//   const handleShowRemoveModal = () => setShowRemoveModal(true);

//   const handleToggleShowPin = () => {
//     setShowPin(!showPin);
//     const inputElement = document.getElementById("setPinInput");
//     if (inputElement) {
//       inputElement.type = showPin ? "password" : "text";
//     }
//   };

//   const toggleBalanceSetting = async () => {
//     try {
//       const payload = { userId: user.id, isAddBalanceEnabled: !checkedPrint };
//       const res = await axios.post(
//         `${process.env.REACT_APP_HOST_URL}/api/pos/setting/enableBalance`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json;charset=UTF-8",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (res.data.success) {
//         setUser({
//           ...user,
//           isAddBalanceEnabled: res.data.data.isAddBalanceEnabled,
//         });
//       }
//     } catch (error) {
//       toast.error("Failed to update balance setting");
//     }
//   };

//   const onChangePrint = (checked) => {
//     setCheckedPrint(checked);
//     toggleBalanceSetting();
//   };

//   const toggleCashCheckout = async () => {
//     try {
//       const payload = { userId: user.id, isCashCheckoutEnabled: !cashCheckout };
//       const res = await axios.post(
//         `${process.env.REACT_APP_HOST_URL}/api/pos/setting/cashCheckout`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json;charset=UTF-8",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (res.data.success) {
//         setUser({
//           ...user,
//           isCashCheckoutEnabled: res.data.data.isCashCheckoutEnabled,
//         });
//       }
//     } catch (error) {
//       toast.error("Failed to update cash checkout setting");
//     }
//   };

//   const onChangeCashCheckout = (checked) => {
//     setCashCheckout(checked);
//     toggleCashCheckout();
//   };

//   let userId = user.id;

//   const handleSave = async () => {
//     if (pin.length !== 4) {
//       toast.error("PIN must be 4 digit");
//     } else {
//       setIsLoading(true);
//       try {
//         const response = await axios.post(
//           `${process.env.REACT_APP_HOST_URL}/api/pos/set-pin`,
//           {
//             userId,
//             pin,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json;charset=UTF-8",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.data.success) {
//           toast.success("PIN Set Successfully");
//           setPin("");
//         } else {
//           toast.error("Failed to setup PIN");
//         }
//       } catch (error) {
//         toast.error("Something went wrong");
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const Bunk = ({ value }) => {
//     return (
//       <button
//         className="d-flex justify-center text-chocolate-700 items-center w-[50px] h-[29px] "
//         style={{ border: "1.5px solid #DDDDDD", borderRadius: "3px" }}
//       >
//         <span className="text-xs font-medium">{value}</span>
//       </button>
//     );
//   };

//   const AddBunkModal = (props) => {
//     const [bunkNumber, setBunkNumber] = useState("");

//     const handleAddBunk = async () => {
//       if (bunkNumber !== "") {
//         try {
//           toast.loading("Adding bunk...");
//           const payload = { userId: user.id, bunkNumber };
//           const res = await axios.post(
//             `${process.env.REACT_APP_HOST_URL}/api/pos/setting/addBunk`,
//             payload,
//             {
//               headers: {
//                 "Content-Type": "application/json;charset=UTF-8",
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );

//           if (res && res.data && res.data.success) {
//             toast.dismiss();
//             toast.success("Bunk Added Successfully");
//             const updatedUser = {
//               ...user,
//               bunks: user.bunks
//                 ? [...user.bunks, { bunkNumber }]
//                 : [{ bunkNumber }],
//             };
//             setUser(updatedUser);
//             setBunks(updatedUser.bunks);
//             setBunkNumber("");
//           } else {
//             toast.dismiss();
//             toast.error(res.data.message);
//           }
//         } catch (error) {
//           toast.dismiss();
//           toast.error(error.response?.data?.message || "Failed to remove bunk");
//         } finally {
//           props.handleClose();
//         }
//       }
//     };
//     const handleSwitch = () => {
//       props.handleClose();
//       props.handleShowRemove();
//     };
//     return (
//       <>
//         <Modal
//           show={props.show}
//           onHide={props.handleClose}
//           dialogClassName="custom-modal"
//           centered
//         >
//           <div className="d-flex flex-column w-[420px] h-[200px]">
//             <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
//               <div
//                 style={{
//                   fontWeight: "700",
//                   fontSize: "21px",
//                   lineHeight: "31.5px",
//                   marginLeft: "33px",
//                 }}
//               >
//                 Add Bunk
//               </div>
//               <button
//                 className="w-[15.5px] h-[15.5px] me-[23px]"
//                 onClick={props.handleClose}
//               >
//                 <img src={Cross} alt="cross" />
//               </button>
//             </div>
//             <div className="d-flex justify-center items-center w-100 h-[40px] mt-[15px]">
//               <div
//                 className="d-flex items-center justify-center w-[230px] h-[40px] "
//                 style={{ background: "#f8f8f8", borderRadius: "9px" }}
//               >
//                 <input
//                   placeholder="Bunk"
//                   style={{
//                     width: "100%",
//                     border: "none",
//                     outline: "none",
//                     backgroundColor: "#f8f8f8",
//                     paddingLeft: "20px",
//                   }}
//                   className="custom-placeholder"
//                   onChange={(e) => setBunkNumber(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="d-flex justify-center items-center h-[30px] mt-[19px]">
//               <button
//                 className="h-[30px] w-[94px]"
//                 style={{
//                   backgroundColor: "#FF8943",
//                   borderRadius: "5px",
//                   color: "#fff",
//                 }}
//                 onClick={() => handleAddBunk()}
//               >
//                 Add
//               </button>
//             </div>
//             <div className="d-flex justify-center h-[20px] mt-[14px]">
//               <button
//                 onClick={handleSwitch}
//                 className="h-[20px]"
//                 style={{
//                   fontWeight: "400",
//                   fontSize: "12px",
//                   lineHeight: "18px",
//                   color: "rgba(0, 0, 0, 0.28)",
//                 }}
//               >
//                 Remove Bunk
//               </button>
//             </div>
//           </div>
//         </Modal>
//         <style>{`
//           .custom-modal .modal-content {
//             border-radius: 20px;
//             width: 420px;
//           }

//           .custom-placeholder::placeholder {
//             color: red;
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 22.5px;
//             color: rgba(0, 0, 0, 0.28);
//           }
//         `}</style>
//       </>
//     );
//   };

//   const RemoveBunkModal = (props) => {
//     const [bunkNumber, setBunkNumber] = useState("");

//     const handleRemoveBunk = async (value) => {
//       if (value !== "") {
//         try {
//           toast.loading("Removing bunk...");
//           const payload = { userId: user.id, bunkNumber: value };
//           const res = await axios.post(
//             `${process.env.REACT_APP_HOST_URL}/api/pos/setting/removeBunk`,
//             payload,
//             {
//               headers: {
//                 "Content-Type": "application/json;charset=UTF-8",
//                 Authorization: `Bearer ${token}`,
//               },
//             }
//           );
//           if (res.data.success) {
//             toast.dismiss();
//             toast.success("Bunk Removed Successfully");
//             const newBunks = bunks.filter((bunk) => {
//               return bunk.bunkNumber !== value;
//             });
//             const updatedUser = {
//               ...user,
//               bunks: newBunks,
//             };
//             setUser(updatedUser);
//             setBunks(newBunks);
//             setBunkNumber("");
//           } else {
//             toast.dismiss();
//             toast.error(res.data.message);
//           }
//         } catch (error) {
//           toast.dismiss();
//           toast.error(error.response?.data?.message || "Failed to remove bunk");
//         } finally {
//           props.handleClose();
//         }
//       }
//     };
//     const handleSwitch = () => {
//       props.handleClose();
//       props.handleShowAdd();
//     };
//     return (
//       <>
//         <Modal
//           show={props.show}
//           onHide={props.handleClose}
//           dialogClassName="custom-modal"
//           centered
//         >
//           <div className="d-flex flex-column w-[420px] h-[200px]">
//             <div className="d-flex justify-between items-center h-[32px] mt-[20px]">
//               <div
//                 style={{
//                   fontWeight: "700",
//                   fontSize: "21px",
//                   lineHeight: "31.5px",
//                   marginLeft: "33px",
//                 }}
//               >
//                 Remove Bunk
//               </div>
//               <button
//                 className="w-[15.5px] h-[15.5px] me-[23px]"
//                 onClick={props.handleClose}
//               >
//                 <img src={Cross} alt="cross" />
//               </button>
//             </div>
//             <div className="d-flex justify-center items-center w-100 h-[40px] mt-[15px]">
//               <div
//                 className="d-flex items-center justify-center w-[230px] h-[40px] "
//                 style={{ background: "#f8f8f8", borderRadius: "9px" }}
//               >
//                 <input
//                   placeholder="Bunk"
//                   style={{
//                     width: "100%",
//                     border: "none",
//                     outline: "none",
//                     backgroundColor: "#f8f8f8",
//                     paddingLeft: "20px",
//                   }}
//                   className="custom-placeholder"
//                   onChange={(e) => setBunkNumber(e.target.value)}
//                 />
//               </div>
//             </div>
//             <div className="d-flex justify-center items-center h-[30px] mt-[19px]">
//               <button
//                 className="h-[30px] w-[94px]"
//                 style={{
//                   backgroundColor: "#FF8943",
//                   borderRadius: "5px",
//                   color: "#fff",
//                 }}
//                 onClick={() => handleRemoveBunk(bunkNumber)}
//               >
//                 Remove
//               </button>
//             </div>
//             <div className="d-flex justify-center h-[20px] mt-[14px]">
//               <button
//                 onClick={handleSwitch}
//                 className="h-[20px]"
//                 style={{
//                   fontWeight: "400",
//                   fontSize: "12px",
//                   lineHeight: "18px",
//                   color: "rgba(0, 0, 0, 0.28)",
//                 }}
//               >
//                 Add Bunk
//               </button>
//             </div>
//           </div>
//         </Modal>
//         <style>{`
//           .custom-modal .modal-content {
//             border-radius: 20px;
//             width: 420px;
//           }

//           .custom-placeholder::placeholder {
//             color: red;
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 22.5px;
//             color: rgba(0, 0, 0, 0.28);
//           }
//         `}</style>
//       </>
//     );
//   };

//   const refreshDashboardData = async () => {
//     try {
//       setLoadingRefresh(true);
//       toast.loading("Refreshing dashboard data...");
//       const payload = { userId: user.id };
//       const response = await axios.post(
//         `${process.env.REACT_APP_HOST_URL}/api/pos/dashboard/regenerateDashboard`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json;charset=UTF-8",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       toast.dismiss();
//       if (response.data.success) {
//         toast.success("Dashboard data refreshed successfully");
//       } else {
//         toast.error(response.data.message);
//       }
//     } catch (error) {
//       toast.dismiss();
//       toast.error("Failed to refresh dashboard data");
//     } finally {
//       setLoadingRefresh(false);
//     }
//   };

//   return (
//     <div
//       className="w-100 h-100 d-flex flex-column px-[10px] py-[15px]"
//       style={{ borderRadius: "20px", gap: "10px" }}
//     >
//       <div
//         className="w-100 h-100 d-flex flex-column overflow-y-auto custom-scrollbar"
//         style={{ borderRadius: "20px", gap: "10px" }}
//       >
//         {/* Kiosk */}
//         <div
//           className="h-[35%] bg-white px-[30px] py-[25px]"
//           style={{ borderRadius: "18px" }}
//         >
//           <div
//             style={{
//               fontSize: "14px",
//               fontWeight: "500",
//               lineHeight: "21px",
//               marginBottom: "19px",
//             }}
//           >
//             <div className="d-flex ">
//               <div className="flex-grow-1 font-medium text-2xl mb-[19px]">Kiosk</div>
//               <div onClick={refreshDashboardData}>
//                 <Refresh check={false} loadingRefresh={loadingRefresh} />
//               </div>
//             </div>
//             <div
//               style={{
//                 fontSize: "14px",
//                 fontWeight: "500",
//                 lineHeight: "21px",
//                 marginBottom: "19px",
//               }}
//             >
//               Go to{" "}
//               <span className="text-chocolate-700">Camppos.com/kiosk</span> or
//               Download the Camp POS App on{" "}
//               <span className="text-chocolate-700">Google Play</span> and{" "}
//               <span className="text-chocolate-700">Play Store</span>.<br /> Use
//               your Login Key and your custom Pin to access the sales Kiosk
//             </div>
//             <div className="d-flex items-center">
//               <div className="d-flex flex-column justify-center h-100 w-[325px] ">
//                 <div className="font-medium text-2xl mb-[20px]">Login Key</div>
//                 <div
//                   className="text-chocolate-700"
//                   style={{ fontSize: "37px" }}
//                 >
//                   {user.loginKey}
//                 </div>
//               </div>
//               <div className="d-flex h-100 items-center">
//                 <div className="d-flex items-center h-100 w-[200px]">
//                   <div
//                     className="d-flex flex-row align-items-center mt-[40px]"
//                     style={{
//                       gap: "2px",
//                       width: "100%",
//                       height: "40px",
//                       borderRadius: "14px",
//                       background: "#E06F2C0F",
//                     }}
//                   >
//                     <span
//                       className="d-flex justify-content-center align-items-center"
//                       style={{
//                         width: "24px",
//                         height: "24px",
//                         marginLeft: "10px",
//                       }}
//                     >
//                       <img
//                         src={pinLock}
//                         width="16px"
//                         height="21px"
//                         alt="lock icon"
//                       />
//                     </span>
//                     <span className="d-flex justify-content-center align-items-center">
//                       <input
//                         type="password"
//                         placeholder="Set PIN"
//                         id="setPinInput"
//                         onChange={(e) => {
//                           const value = e.target.value.replace(/\D/g, "");
//                           setPin(value);
//                         }}
//                         value={pin}
//                         size="18"
//                         style={{
//                           border: "none",
//                           backgroundColor: "transparent",
//                           padding: "5px",
//                           outline: "none",
//                           width: "100%",
//                         }}
//                       />
//                     </span>
//                     <div className="me-[20px]" onClick={handleToggleShowPin}>
//                       {showPin ? <FaRegEye /> : <FaRegEyeSlash />}
//                     </div>
//                   </div>
//                 </div>
//                 <div>
//                   <div className="d-flex flex-row justify-content-center mt-[40px] ps-[20px]">
//                     <button
//                       onClick={handleSave}
//                       disabled={isLoading}
//                       style={{
//                         width: "90px",
//                         height: "40px",
//                         borderRadius: "14px",
//                         background:
//                           "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
//                         boxShadow: "0px 8px 21px 0px #00000029",
//                         border: "0",
//                         fontWeight: 700,
//                         size: "10px",
//                         color: "#FFFFFF",
//                       }}
//                     >
//                       {isLoading ? <Spinner size="sm" /> : "Save PIN"}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Bunks  */}
//         <div
//           className="bg-white px-[30px] py-[25px]"
//           style={{ borderRadius: "18px" }}
//         >
//           <div className="font-medium  text-2xl">Bunks</div>
//           <div
//             className="d-flex flex-wrap h-[80px] mt-2 overflow-y-auto custom-scrollbar"
//             style={{ gap: "12px" }}
//           >
//             {bunks
//               .sort((a, b) => parseInt(a.bunkNumber) - parseInt(b.bunkNumber))
//               .map((item, index) => {
//                 return <Bunk key={index} value={item.bunkNumber} />;
//               })}

//             <button
//               className="d-flex justify-center items-center w-[50px] h-[29px] text-white bg-chocolate-700"
//               style={{ border: "1px solid #DDDDDD", borderRadius: "3px" }}
//               onClick={handleShowAddModal}
//             >
//               <span className="text-3xl">+</span>
//             </button>
//           </div>
//         </div>

//         {/* Camper Setting  */}
//         <div
//           className="h-[25%] bg-white px-[30px] py-[25px] justify-center d-flex flex-col"
//           style={{ borderRadius: "18px" }}
//         >
//           <div className="d-flex flex-col justify-between h-[100%]">
//             <div className="d-flex items-center h-[50%] w-[100%]">
//               <div className="d-flex flex-row justify-between w-full">
//                 <div
//                   className="font-medium pe-[50px]"
//                   style={{ fontSize: "17px" }}
//                 >
//                   Add Balance to accounts from kiosk -{" "}
//                   <span style={{ fontSize: "16px" }}>
//                     when this is on it will allow the kiosk operator to add
//                     balances to accounts from his kiosk. this will only apply to
//                     camper accounts
//                   </span>
//                 </div>
//                 <div>
//                   <Switch
//                     className={`${checkedPrint ? "" : "custom-switch"}`}
//                     onChange={onChangePrint}
//                     checked={checkedPrint}
//                     style={{
//                       backgroundColor: checkedPrint
//                         ? "rgba(224, 111, 44, 1)"
//                         : "rgba(248, 248, 248, 1)",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>

//             <div
//               className="mx-[2%] mt-[15px]"
//               style={{ border: "1px solid #EAEAEA", width: "92%" }}
//             />

//             <div className="d-flex items-center h-[50%] w-full mt-[15px]">
//               <div className="d-flex flex-row items-center justify-between w-[100%]">
//                 <div
//                   className="font-medium pe-[50px]"
//                   style={{ fontSize: "17px" }}
//                 >
//                   Cash Checkout without an account -{" "}
//                   <span style={{ fontSize: "16px" }}>
//                     when this is on it will allow the kiosk operator to place
//                     orders of cash without an account entry
//                   </span>
//                 </div>
//                 <div>
//                   <Switch
//                     className={`${cashCheckout ? "" : "custom-switch"}`}
//                     onChange={onChangeCashCheckout}
//                     checked={cashCheckout}
//                     style={{
//                       backgroundColor: cashCheckout
//                         ? "rgba(224, 111, 44, 1)"
//                         : "rgba(248, 248, 248, 1)",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* POS Username and Password */}
//         <div
//           className="h-[25%] bg-white px-[30px] py-[25px]"
//           style={{ borderRadius: "18px" }}
//         >
//           <div className="font-medium text-2xl">POS Dashboard login</div>
//           <div className="text-mini">
//             Set Your Private Password for the pos dashboard.
//           </div>
//           <div
//             className="d-flex items-center mt-[15px]"
//             style={{ gap: "20px" }}
//           >
//             <div
//               className="d-flex flex-row align-items-center  px-[18px]"
//               style={{
//                 gap: "6px",
//                 width: "25%",
//                 height: "45px",
//                 borderRadius: "16px",
//                 background: "#E06F2C0F",
//               }}
//             >
//               <span
//                 className="d-flex justify-content-center align-items-center mr-[6px]"
//                 style={{
//                   width: "24px",
//                   height: "24px",
//                 }}
//               >
//                 <img src={userIcon} alt="user" />
//               </span>
//               <span className="d-flex justify-content-center align-items-center w-[100%]">
//                 <input
//                   type="text"
//                   disabled={true}
//                   placeholder="Username"
//                   value={user.userName}
//                   id="userName"
//                   name="userName"
//                   size="18"
//                   style={{
//                     border: "none",
//                     backgroundColor: "transparent",
//                     padding: "5px",
//                     outline: "none",
//                     width: "100%",
//                   }}
//                 />
//               </span>
//             </div>
//             <div
//               className="d-flex flex-row align-items-center px-[18px]"
//               style={{
//                 gap: "6px",
//                 width: "25%",
//                 height: "45px",
//                 borderRadius: "16px",
//                 background: "#E06F2C0F",
//               }}
//             >
//               <span
//                 className="d-flex justify-content-center align-items-center mr-[6px]"
//                 style={{
//                   width: "24px",
//                   height: "24px",
//                 }}
//               >
//                 <img src={lock} width="16px" height="21px" alt="lock icon" />
//               </span>
//               <span className="d-flex justify-content-center align-items-center w-[100%]">
//                 <input
//                   type={showPosPassword ? "text" : "password"}
//                   placeholder="Password"
//                   id="input"
//                   onChange={handleChange}
//                   name="password"
//                   size="18"
//                   style={{
//                     border: "none",
//                     backgroundColor: "transparent",
//                     padding: "5px",
//                     outline: "none",
//                     width: "100%",
//                   }}
//                 />
//               </span>
//               <div
//                 className="me-[20px]"
//                 onClick={() => setShowPosPassword(!showPosPassword)}
//               >
//                 {showPosPassword ? <FaRegEye /> : <FaRegEyeSlash />}
//               </div>
//             </div>
//             <div>
//               <button
//                 style={{
//                   width: "150px",
//                   height: "45px",
//                   borderRadius: "14px",
//                   background:
//                     "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
//                   boxShadow: "0px 8px 21px 0px #00000029",
//                   border: "0",
//                   fontWeight: 700,
//                   size: "10px",
//                   color: "#FFFFFF",
//                 }}
//                 onClick={handleEditPassword}
//               >
//                 {loading ? <Spinner size="sm" /> : "Save Password"}
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       <AddBunkModal
//         show={showAddModal}
//         setShow={setShowAddModal}
//         handleClose={handleCloseAddModal}
//         setBunks={setBunks}
//         handleShowRemove={handleShowRemoveModal}
//       />

//       <RemoveBunkModal
//         show={showRemoveModal}
//         setShow={setShowRemoveModal}
//         handleClose={handleCloseRemoveModal}
//         setBunks={setBunks}
//         handleShowAdd={handleShowAddModal}
//       />
//     </div>
//   );
// }

// export default Setting;
