import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import meatballsMenu from "../../assets/meatballsMenu.svg";

const ItemTypes = {
  CATEGORY: "category",
};

const Category = ({
  category,
  onClick,
  isSelected,
  handleDrop,
  onEdit
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CATEGORY,
    item: { categoryId: category.categoryId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemTypes.CATEGORY,
    drop: (draggedItem) => {
      if (draggedItem.categoryId !== category.categoryId) {
        handleDrop(draggedItem.categoryId, category.categoryId);
      }
    },
  }));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsDropdownVisible(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      key={category.categoryId}
      className={`w-[109px] h-[132px] transition-transform duration-500 ease-in-out ${
        isSelected ? "text-sienna" : ""
      }`}
      style={{
        marginRight: "15px",
        position: "relative",
        opacity: isDragging ? 0.5 : 1,
        minWidth:"109px"
      }}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div
          style={{
            position: "absolute",
            top: "5px",
            left: "5px",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={toggleDropdown}
        >
          <div className="w-[24px] h-[24px] object-fit">
            <img src={meatballsMenu} alt=":" />
          </div>
          {isDropdownVisible && (
            <div
              className="d-flex justify-center items-center w-[140px] h-[35px]"
              style={{
                position: "absolute",
                top: "20px",
                left: "0px",
                background: "#f8f8f8",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#E06F2C";
                e.target.style.color = "#FFFFFF";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "";
                e.target.style.color = "";
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
              >
                Edit Category
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`rounded-lg border-[1px] border-solid w-full h-full cursor-pointer d-flex flex-col justify-center items-center ${
          isSelected
            ? "bg-chocolate-300 border-[#E66E27]"
            : "bg-white border-lightgray-100"
        }`}
      >
        <div className="h-[60%] w-full d-flex items-end justify-center">
          <img
            className="w-[51px] h-[51px]"
            alt={category.categoryName}
            src={category.categoryImgUrl
            }
          />
        </div>

        <div className="h-[40%] w-full d-flex justify-center py-[6px]">
          <div
            className={`text-center font-nunito w-[80px]  ${
              isSelected ? "text-sienna" : "text-gray-500"
            }`}
            style={{
              lineHeight: "20.46px",
              fontSize: "15px",
              color: isSelected ? "#704332" : "#A69D9A",
              fontWeight: "600",
            }}
          >
            {category.categoryName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
