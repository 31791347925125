import { useState, useEffect, useContext } from "react";
import Category from "./category";
import filter from "../../assets/allProduct.svg";
import AddCategory from "../../assets/AddCategory.svg";
import CategoryButton from "./categoryButton";
import CategoryContent from "./CategoryModalContent/categoryContent";
import Modal from "../Common/modal";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AuthContext } from "../../auth/AuthProvider";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";

const Categories = ({
  categories,
  isLoading,
  setCategories,
  setSelectedCategoryId,
}) => {
  const [isSelected, setIsSelected] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const { user, token } = useContext(AuthContext);
  const userId = user.id;

  useEffect(() => {
    if (selectedCategory === null) {
      setIsSelected(true);
    }
  }, [selectedCategory]);

  const handleCategorySelect = (index) => {
    if (index && !selectedCategory) {
      setIsSelected(false);
      setSelectedCategory((prevSelected) =>
        prevSelected === index ? null : index
      );
      setSelectedCategoryId(index);
    } else if (selectedCategory === index) {
      setIsSelected(true);
      handleAllProductsSelect();
    } else {
      setSelectedCategory((prevSelected) =>
        prevSelected === index ? null : index
      );
      setSelectedCategoryId(index);
    }
  };

  const handleAllProductsSelect = () => {
    setSelectedCategory(null);
    setIsSelected(true);
    setSelectedCategoryId("");
  };

  const toggleCategoryModal = () => {
    setIsCategoryModalOpen((prev) => !prev);
  };

  const handleClose = () => {
    setEditCategory(null);
    setIsCategoryModalOpen(false);
  };

  const handleCategoryUpdate = (updatedCategory) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.categoryId === updatedCategory.categoryId
          ? updatedCategory
          : category
      )
    );
  };

  const handleCategoryCreate = (newCategory) => {
    setCategories((prevCategories) => [...prevCategories, newCategory]);
  };

  const handleCategoryDelete = (categoryId) => {
    setCategories((prevCategories) =>
      prevCategories.filter((cat) => cat.categoryId !== categoryId)
    );
  };

  const handleEditCategory = (category) => {
    setEditCategory(category);
    toggleCategoryModal();
  };

  const handleDrop = async (draggedId, hoveredId) => {
    const draggedIndex = categories.findIndex(
      (c) => c.categoryId === draggedId
    );
    const hoveredIndex = categories.findIndex(
      (c) => c.categoryId === hoveredId
    );

    if (draggedIndex !== -1 && hoveredIndex !== -1) {
      const newCategories = [...categories];
      [newCategories[draggedIndex], newCategories[hoveredIndex]] = [
        newCategories[hoveredIndex],
        newCategories[draggedIndex],
      ];
      setCategories(newCategories);

      let firstCategoryId = draggedId;
      let secondCategoryId = hoveredId;
      try {
        await axios.post(
          `${process.env.REACT_APP_HOST_URL}/api/pos/category/drag`,
          {
            userId,
            firstCategoryId,
            secondCategoryId,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        alert("Something went wrong:::", error.message);
      }
    }
  };

  return (
    <>
      <div className="flex flex-row items-center w-[100%]">
        <CategoryButton
          categoryName={"All Products"}
          imageUrl={filter}
          onClick={handleAllProductsSelect}
          isSelected={isSelected}
        />
        {isLoading ? (
          <div className="me-3">
            <Spinner size="lg" color="#FF8943" />
          </div>
        ) : (
          <DndProvider backend={HTML5Backend}>
            {categories.map((category, index) => (
              <Category
                key={`${category.categoryId}-${index}`}
                category={category}
                onClick={() => handleCategorySelect(category.categoryId)}
                handleDrop={handleDrop}
                isSelected={selectedCategory === category.categoryId}
                onEdit={() => handleEditCategory(category)}
              />
            ))}
          </DndProvider>
        )}

        <CategoryButton
          categoryName={"Add Category"}
          imageUrl={AddCategory}
          onClick={toggleCategoryModal}
        />
      </div>
      <Modal
        isVisible={isCategoryModalOpen}
        onClose={handleClose}
        backgroundColor={"white"}
      >
        <CategoryContent
          handleClose={handleClose}
          handleCategory={handleCategoryCreate}
          handleCategoryUpdate={handleCategoryUpdate}
          handleCategoryDelete={handleCategoryDelete}
          editCategory={editCategory}
        />
      </Modal>
    </>
  );
};
export default Categories;
