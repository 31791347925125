import React, { useState } from "react";
import bulkProductSample from "../../assets/bulkProductSample.png";
import toast from "react-hot-toast";
import axios from "axios";

const BulkUploadContent = (props) => {
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [uploadedCategories, setUploadedCategories] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && !file.name.endsWith(".csv")) {
      toast.error("Please select a CSV file");
      return;
    }
    setFile(file);

    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result;
      const rows = csvData.split("\n");
      const jsonProducts = rows.slice(1).map((row) => {
        const values = row.split(",");
        return {
          productName: values[0],
          category: values[1],
          cost: parseFloat(values[2].replace("$", "")),
          price: parseFloat(values[3].replace("$", "")),
          stock: parseInt(values[4]),
        };
      });

      setProducts(jsonProducts);

      const uniqueCategories = new Set(
        jsonProducts.map((product) => product.category)
      );
      setUploadedCategories([...uniqueCategories]);
    };

    reader.readAsText(file);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  const handleUpload = async () => {
    if (!file || products.length === 0) {
      toast.error("Please select a file to upload");
      return;
    }

    if (!props.categories || !Array.isArray(props.categories)) {
      toast.error("Failed to fetch categories");
      return;
    }

    const existingCategories = props.categories.map((category) => ({
      categoryName: category.categoryName.toLowerCase(),
      categoryId: category.categoryId,
    }));

    const missingCategories = uploadedCategories.filter(
      (category) =>
        !existingCategories.some(
          (c) => c.categoryName === category.toLowerCase()
        )
    );

    const invalidProducts = products.filter(
      (product) =>
        !existingCategories.some(
          (c) => c.categoryName === product.category.toLowerCase()
        )
    );

    if (invalidProducts.length > 0) {
      toast.error(`Invalid products found. Please check the categories.`);
      return;
    }

    const productWithCategoryIds = products.map((product) => {
      const category = existingCategories.find(
        (c) => c.categoryName === product.category.toLowerCase()
      );
      return {
        ...product,
        categoryId: category ? category.categoryId : null,
      };
    });

    try {
      toast.loading("Uploading products...");
      const payload = {
        products: productWithCategoryIds,
        userId: props.userId,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/product/bulkUpload`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      if (res && res.data && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.handleUpload(res.data.data);
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }

    props.handleClose();
  };

  return (
    <div
      className="d-flex flex-column mb-[41px] mt-[18px] mx-[5%]"
      style={{ width: "auto" }}
    >
      <div className="d-flex justify-between items-center ">
        <div
          style={{
            fontWeight: "700",
            fontSize: "21px",
            lineHeight: "31.5px",
          }}
        >
          Bulk Product Upload
        </div>
      </div>

      <div className="d-flex flex-col items-center w-[100%] h-[80%] mt-[16px] mb-[40px] gap-[25px]">
        <div className="w-[100%] h-[63px] flex flex-col justify-center items-center">
          <div className="font-medium text-[14px] leading-[21px] text-center text-[#12141E]">
            Sample Upload
          </div>
          <div className="font-normal text-[14px] leading-[21px] text-center text-[#12141E]">
            (the first row should have the Name . . . as described below. if a
            square is blank that account will be missing the information of that
            square. save the sheet as a (CSV? Something else?))
          </div>
        </div>

        <div className="flex items-center justify-center rounded-[5px] overflow-hidden">
          <img
            src={bulkProductSample}
            alt="Sample Image"
            className="object-cover w-[100%]"
          />
        </div>

        <input type="file" onChange={handleFileChange} />
      </div>

      <div className="d-flex justify-center items-center h-[30px] gap-[11px]">
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: "#E0E0E0",
            borderRadius: "5px",
            color: "#000000",
          }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: "#FF8943",
            borderRadius: "5px",
            color: "#fff",
          }}
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default BulkUploadContent;
