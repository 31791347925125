import Camper from "../assets/Camper.png";
import Cost from "../assets/Cost.png";
import Staff from "../assets/Staff.png";
import CamperWithNo from "../assets/camperwithNo.png";
import CostWithNo from "../assets/costStaffWithNo.png";

export const tabType = [
  { label: "Camper", value: "camper", src: Camper, srcWithNo: CamperWithNo },
  { label: "Staff", value: "staff", src: Staff, srcWithNo: CostWithNo},
  { label: "Cost", value: "cost", src: Cost, srcWithNo: CostWithNo },
];

export const itemsType = [
  { label: "Camper", value: "camper" },
  { label: "Staff", value: "staff" },
  { label: "Cost", value: "cost" },
];
