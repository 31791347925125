import React, { useContext, useEffect, useState } from "react";
import Cross from "../../../assets/Cross.svg";
import { Checkbox, Spinner } from "@chakra-ui/react";
import SelectableGrid from "../../Common/selectableGrid";
import axios from "axios";
import toast from "react-hot-toast";
import { AuthContext } from "../../../auth/AuthProvider";

const CategoryContent = ({
  handleClose,
  handleCategory,
  handleCategoryUpdate,
  editCategory,
  handleCategoryDelete,
}) => {
  const { user, token, categoryIcons } = useContext(AuthContext);
  const [categoryData, setCategoryData] = useState({
    categoryName: editCategory?.categoryName || "",
    categoryImgUrl: editCategory?.categoryImgUrl || "",
    ind: editCategory?.ind,
  });

  const handleCategorySelection = (selectedCategory) => {
    setCategoryData({
      ...categoryData,
      categoryImgUrl: selectedCategory.categoryImgUrl,
      ind: selectedCategory.ind,
    });
  };

  const userId = user.id;

  const handleCreate = async () => {
    try {
      toast.loading("Creating category...");
      const payloadCreate = { ...categoryData, userId };
      const url = `${process.env.REACT_APP_HOST_URL}/api/pos/category/create`;

      const method = "post";

      const res = await axios({
        method: method,
        url: url,
        data: payloadCreate,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss();
      toast.success("Created category");
      handleCategory(res.data.data);
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
    handleClose();
  };

  const handleSave = async () => {
    try {
      toast.loading("Updating Category...");
      const payloadUpdate = {
        ...categoryData,
        userId,
        categoryId: editCategory.categoryId,
        pos: editCategory.pos,
      };
      const url = `${process.env.REACT_APP_HOST_URL}/api/pos/category/update`;

      const method = "put";

      const res = await axios({
        method: method,
        url: url,
        data: payloadUpdate,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss();
      toast.success("Category Updated");
      handleCategoryUpdate(res.data.data);
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }
    handleClose();
  };

  const handleDelete = async () => {
    try {
      toast.loading("Deleting category...");
      const res = await axios.delete(
        `${process.env.REACT_APP_HOST_URL}/api/pos/category/delete`,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
          data: {
            userId,
            categoryId: editCategory.categoryId,
          },
        }
      );

      if (res?.data?.success) {
        toast.dismiss();
        toast.success(res.data.message);
        handleCategoryDelete(
          res.data.data?.categoryId || editCategory.categoryId
        );
        handleClose();
      } else {
        throw new Error(res?.data?.message || "Unknown error");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const Category = ({ onClick, isSelected, item }) => {
    const handleClick = () => {
      if (onClick) {
        onClick();
        handleCategorySelection(item);
      }
    };

    const containerBorder = {
      border: isSelected ? "2px solid #E06F2C" : "none",
      borderColor: isSelected ? "#E06F2C" : "#F8F8F8",
    };

    return (
      <div
        className="h-[100px] w-[100px] d-flex justify-center items-center relative"
        style={{
          backgroundColor: "#F8F8F8",
          borderRadius: "7px",
          ...containerBorder,
          transition: "background-color 0.3s, border-color 0.3s",
        }}
        onClick={handleClick}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f5937f")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#F8F8F8")}
      >
        <div className="absolute top-[5px] left-[6px]">
          <Checkbox
            isChecked={isSelected}
            colorScheme="white"
            onChange={handleClick}
            css={{
              backgroundColor: "white",
              border: "none",
              ".css-hktoxc[data-checked]": {
                color: "#FF8943",
              },
              ".chakra-checkbox__control": {
                border: "none",
              },
            }}
          />
        </div>
        <img
          src={item.categoryImgUrl}
          alt="category"
          className="w-[67px] h-[67px] ml-[8px] mt-[9px]"
        />
      </div>
    );
  };

  return (
    <div className="p-[20px] flex flex-col gap-[25px]">
      <div className="flex flex-row justify-between">
        <div
          className="font-bold"
          style={{ fontSize: "16px", lineHeight: "24px", color: "#12141E" }}
        >
          {editCategory ? "Edit Category" : "New Category"}
        </div>

        <button className="w-[15.5px] h-[15.5px]" onClick={handleClose}>
          <img src={Cross} alt="cross" />
        </button>
      </div>

      <div className="d-flex items-center justify-center w-full h-[40px]">
        <div className="w-[50%] h-[40px]">
          <input
            placeholder="Category Name"
            value={categoryData.categoryName}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#f8f8f8",
              paddingLeft: "20px",
              paddingRight: "20px",
              width: "100%",
              height: "100%",
              borderRadius: "7px",
            }}
            className="custom-placeholder"
            onChange={(e) =>
              setCategoryData({ ...categoryData, categoryName: e.target.value })
            }
          />
        </div>
      </div>

      <div
        style={{
          textAlign: "center",
          color: "#12141E",
          width: "100%",
          fontWeight: "500",
        }}
      >
        Choose an Icon that most resembles this Category
      </div>

      <div className="h-[400px] custom-scrollbar overflow-y-auto">
        <SelectableGrid cols="4" items={categoryIcons}>
          {({ onClick, isSelected, item }) => (
            <Category
              onClick={onClick}
              isSelected={categoryData.ind === item.ind || isSelected}
              item={item}
            />
          )}
        </SelectableGrid>
      </div>

      <div className="d-flex flex-col justify-center items-center gap-[12px]">
        <button
          className="h-[30px] w-[94px]"
          style={{
            backgroundColor: "#FF8943",
            borderRadius: "5px",
            color: "#fff",
          }}
          onClick={editCategory ? handleSave : handleCreate}
        >
          {editCategory ? "Save" : "Create"}
        </button>
        {editCategory && (
          <button
            className="h-[18px]"
            style={{
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: "400",
              color: "rgba(0, 0, 0, 0.28)",
            }}
            onClick={handleDelete}
          >
            Delete Category
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoryContent;
