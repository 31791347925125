import React, { useState } from "react";
import CustomInput from "../../Common/customInput";
import { Switch } from "antd";
import stripe from "../../../assets/stripe.svg";

function OnlinePaymentsSetting() {
  const [settings, setSettings] = useState({
    enableOnlinePayments: true,
    sendEReceipt: true,
    applyTransactionFee: true,
    showBalance: true,
    showTotalSpent: true,
  });

  const handleSettingChange = (settingKey) => (checked) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [settingKey]: checked,
    }));
  };

  const getSwitchProps = (settingKey) => ({
    className: `${settings[settingKey] ? "" : "custom-switch"}`,
    onChange: handleSettingChange(settingKey),
    checked: settings[settingKey],
    style: {
      backgroundColor: settings[settingKey]
        ? "rgba(224, 111, 44, 1)"
        : "rgba(248, 248, 248, 1)",
    },
  });

  return (
    <div className="px-24 py-16">
      <div className="flex items-center w-100">
        <div className="flex-grow-1 font-[500] text-[20px]">
          Enable this setting to allow parents to add Money to Campers account
          online.{" "}
        </div>
        <div>
          <Switch {...getSwitchProps("enableOnlinePayments")} />
        </div>
      </div>

      {settings.enableOnlinePayments && (
        <div className="flex flex-col gap-3 lg:gap-4 mt-6">
          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="font-[500] text-[20px]"> Contact Info </div>
          <div className="grid grid-cols-2 gap-4 mt-2 w-[70%]">
            <CustomInput placeholder="First Name" />
            <CustomInput placeholder="Last Name" />
            <CustomInput placeholder="Phone Number" />
            <CustomInput placeholder="Email" />
          </div>

          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="flex items-center">
            <div className="flex-grow-1 text-[20px] font-[500]">
              Send Automatic E-Receipt After Order
            </div>
            <div>
              <Switch {...getSwitchProps("sendEReceipt")} />
            </div>
          </div>

          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="flex items-center">
            <div className="flex-grow-1 text-[20px] font-[500]">
              Apply 3% Transaction Fee to Each Order
            </div>
            <div>
              <Switch {...getSwitchProps("applyTransactionFee")} />
            </div>
          </div>

          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="flex items-center">
            <div className="flex-grow-1 text-[20px] font-[500]">
              Show Child's Current Balance on Account
            </div>
            <div>
              <Switch {...getSwitchProps("showBalance")} />
            </div>
          </div>

          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="flex items-center">
            <div className="flex-grow-1 text-[20px] font-[500]">
              Show Total Amount Spent by Child
            </div>
            <div>
              <Switch {...getSwitchProps("showTotalSpent")} />
            </div>
          </div>

          <div style={{ border: "1px solid #EAEAEA" }} />
          <div className="px-8 py-6 border-1 rounded-xl flex mt-2">
            <div className="flex-grow-1">
            <img src={stripe} />
            </div>
            <button className="bg-[#E06F2C] text-white px-10 rounded-xl">
               Connect to Stripe
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OnlinePaymentsSetting;
