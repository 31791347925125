import React, { useState, useContext } from "react";
import userIcon from "../../../assets/user.svg";
import { AuthContext } from "../../../auth/AuthProvider";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Spinner } from "@chakra-ui/react";
import pinLock from "../../../assets/pinLock.svg";

import lock from "../../../assets/lock.svg";
import axios from "axios";
import toast from "react-hot-toast";

function LoginInfo() {
  const { user, token, setUser } = useContext(AuthContext);
  const [posPassword, setPosPasword] = useState("");
  const [showPosPassword, setShowPosPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setPosPasword(e.target.value);
  };

  const handleEditPassword = async () => {
    try {
      setLoading(true);
      const payload = { userId: user.id, password: posPassword };
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/setting/editPassword`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        toast.success("Password updated successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error("Failed to update password");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleShowPin = () => {
    setShowPin(!showPin);
    const inputElement = document.getElementById("setPinInput");
    if (inputElement) {
      inputElement.type = showPin ? "password" : "text";
    }
  };

  let userId = user.id;

  const handleSave = async () => {
    if (pin.length !== 4) {
      toast.error("PIN must be 4 digit");
    } else {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_HOST_URL}/api/pos/set-pin`,
          {
            userId,
            pin,
          },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          toast.success("PIN Set Successfully");
          setPin("");
        } else {
          toast.error("Failed to setup PIN");
        }
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 px-24 py-12">
      <div>
        <div className="font-medium text-2xl">POS Dashboard login</div>
        <div className="text-mini">
          Set Your Private Password for the pos dashboard.
        </div>
        <div className="d-flex items-center mt-[15px]" style={{ gap: "20px" }}>
          <div className="flex gap-4 flex-grow-1 ">
            <div
              className="d-flex flex-row align-items-center px-[18px]"
              style={{
                gap: "6px",
                width: "50%",
                height: "45px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center mr-[6px]"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              >
                <img src={userIcon} alt="user" />
              </span>
              <span className="d-flex justify-content-center align-items-center w-[100%]">
                <input
                  type="text"
                  disabled={true}
                  placeholder="Username"
                  value={user.userName}
                  id="userName"
                  name="userName"
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
            </div>
            <div
              className="d-flex flex-row align-items-center px-[18px]"
              style={{
                gap: "6px",
                width: "45%",
                height: "45px",
                borderRadius: "16px",
                background: "#E06F2C0F",
              }}
            >
              <span
                className="d-flex justify-content-center align-items-center mr-[6px]"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              >
                <img src={lock} width="16px" height="21px" alt="lock icon" />
              </span>
              <span className="d-flex justify-content-center align-items-center w-[100%]">
                <input
                  type={showPosPassword ? "text" : "password"}
                  placeholder="Password"
                  id="input"
                  onChange={handleChange}
                  name="password"
                  size="18"
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    padding: "5px",
                    outline: "none",
                    width: "100%",
                  }}
                />
              </span>
              <div
                className="me-[20px]"
                onClick={() => setShowPosPassword(!showPosPassword)}
              >
                {showPosPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
            </div>
          </div>
          <div>
            <button
              style={{
                width: "150px",
                height: "45px",
                borderRadius: "14px",
                background:
                  "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
                boxShadow: "0px 8px 21px 0px #00000029",
                border: "0",
                fontWeight: 700,
                size: "10px",
                color: "#FFFFFF",
              }}
              onClick={handleEditPassword}
            >
              {loading ? <Spinner size="sm" /> : "Save Password"}
            </button>
          </div>
        </div>
      </div>

      <div style={{ border: "1px solid #EAEAEA" }} />

      <div
        style={{
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "21px",
          marginBottom: "19px",
        }}
      >
        <div className="d-flex ">
          <div className="flex-grow-1 font-medium text-2xl mb-[19px]">
            Kiosk
          </div>
          {/* <div onClick={refreshDashboardData}>
            <Refresh check={false} loadingRefresh={loadingRefresh} />
          </div> */}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21px",
            marginBottom: "19px",
          }}
        >
          Go to <span className="text-chocolate-700">Camppos.com/kiosk</span> or
          Download the Camp POS App on{" "}
          <span className="text-chocolate-700">Google Play</span> and{" "}
          <span className="text-chocolate-700">Play Store</span>.<br /> Use your
          Login Key and your custom Pin to access the sales Kiosk
        </div>
    

        <div className="text-[20px] font-medium">Login Key</div>
        <div className="flex gap-4 items-center mt-2">
          <div className="text-[#E06F2C] text-[36px] flex-grow-1">
            {" "}
            {user.loginKey}
          </div>
          <div className="w-[30%]">
            {" "}
            <div
              className="flex items-center"
              style={{
                gap: "2px",
                width: "100%",
                height: "40px",
                borderRadius: "14px",
                background: "#E06F2C0F",
              }}
            >
              <div className="flex items-center flex-grow-1">
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={pinLock}
                    width="16px"
                    height="21px"
                    alt="lock icon"
                  />
                </span>
                <span className="d-flex justify-content-center align-items-center">
                  <input
                    type="password"
                    placeholder="Set PIN"
                    id="setPinInput"
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setPin(value);
                    }}
                    value={pin}
                    size="18"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      padding: "5px",
                      outline: "none",
                      width: "100%",
                    }}
                  />
                </span>
              </div>
              <div className="me-[20px]" onClick={handleToggleShowPin}>
                {showPin ? <FaRegEye /> : <FaRegEyeSlash />}
              </div>
            </div>
          </div>
          <div>
            {" "}
            <button
              onClick={handleSave}
              disabled={isLoading}
              style={{
                width: "90px",
                height: "40px",
                borderRadius: "14px",
                background:
                  "linear-gradient(99.78deg, #E06F2C -5.85%, #FF9F66 109.55%)",
                boxShadow: "0px 8px 21px 0px #00000029",
                border: "0",
                fontWeight: 700,
                size: "10px",
                color: "#FFFFFF",
              }}
            >
              {isLoading ? <Spinner size="sm" /> : "Save PIN"}
            </button>
          </div>
        </div>
      </div>

      <div style={{ border: "1px solid #EAEAEA" }} />

      <div className="flex items-center">
        <div className="flex-grow-1 font-[500]">
          {" "}
          Logout of your Kiosk on all devises{" "}
        </div>
        <button
          className="shadow text-white px-7 rounded-lg py-2 text-[17px]"
          style={{
            background:
              "linear-gradient(99.78deg, #D20000 -5.85%, #D84545 109.55%)",
          }}
        >
          Logout
        </button>
      </div>

      <div style={{ border: "1px solid #EAEAEA" }} />

      <div className="flex items-center">
        <div className="flex-grow-1 font-[500]">
          {" "}
          Logout of the Campos Dashboard on all devises{" "}
        </div>
        <button
          className="shadow text-white px-7 rounded-lg py-2 text-[17px]"
          style={{
            background:
              "linear-gradient(99.78deg, #D20000 -5.85%, #D84545 109.55%)",
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default LoginInfo;
