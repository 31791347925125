import { Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./pages/Login";
import PrivateRoute from "./components/privateRoute";
import Layout from "./layout/Layout";
import Menu from "./pages/Menu";
function App() {
  return (
    <ChakraProvider>
      <div>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="" element={<PrivateRoute />}>
              <Route path="/*" element={<Layout />} />
              <Route path="/menu" element={<Menu />} />
            </Route>
          </Routes>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
