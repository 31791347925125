import React from "react";

function InputWithLabel({label, value, onChange}) {
  return (
    <div className="flex flex-col w-full">
      <div className="ml-10 text-[#1C1C1C] text-xs mb-1">{label}</div>
      <div
        className="d-flex flex-row align-items-center px-2"
        style={{
          gap: "2px",
          width: "100%",
          height: "50px",
          borderRadius: "12px",
          background: "#E06F2C0F",
        }}
      >
        <input
          type="text"
          placeholder=""
          size="18"
          value={value}
          onChange= {onChange}
          style={{
            border: "none",
            backgroundColor: "transparent",
            padding: "5px",
            outline: "none",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}

export default InputWithLabel;
