import Accounts from "../components/Accounts";
import AllOrders from "../components/allOrders/allOrders";
import GridLayout from "../components/GridLayout/GridLayout";
import Products from "../components/Products";
import Setting from "../components/Settings/Setting";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import AccountView from "../components/accountView/AccountView";
import Transactions from "../components/Transactions";

export const routes = [
  {
    layout: "admin",
    pages: [
      {
        name: "Dashboard",
        path: "/dashboard",
        element: <GridLayout />,
      },
      {
        name: "Products",
        path: "/products",
        element: <Products />,
      },
      {
        name: "Orders",
        path: "/orders",
        element: <AllOrders />,
      },
      {
        name: "Accounts",
        path: "/accounts",
        element: <Accounts />,
      },
      {
        name: "Setting",
        path: "/setting",
        element: <Setting />,
      },
      {
        name: "Transactions",
        path: "/transactions",
        element: <Transactions />,
      },
      {
        name: "Product Details",
        path: "/products/product-details",
        element: <ProductDetails />,
      },
      {
        name: "Account Details",
        path: "/accounts/account-details/:accountId",
        element: <AccountView />,
      },
    ],
  },
  // {
  //   layout: "auth",
  //   pages: [
  //     {
  //       name: "Login",
  //       path: "/login",
  //       element: <Login />,
  //     },
  //   ],
  // },
];
