import React from "react";

function CustomInput({ placeholder }) {
  return (
    <div className="bg-[#E06F2C0F] rounded-lg p-3 px-4  focus-within:border-[#E06F2C] focus-within:border-[1px]">
      <input
        type="text"
        placeholder={placeholder}
        className="outline-none bg-transparent "
      />
    </div>
  );
}

export default CustomInput;
