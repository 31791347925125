import React, { useState, useEffect } from "react";
import { Segmented } from "antd";
import { tabType } from "../../data/AccountsData";
import toast from "react-hot-toast";
import axios from "axios";
import { AutoGenNumber } from "../../utils";

const BulkUploadModalcontent = (props) => {
  const [alignValue, setAlignValue] = useState("camper");
  const [checked, setChecked] = useState(false);
  const [file, setFile] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && !file.name.endsWith(".csv")) {
      toast.error("Please select a CSV file");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const csvData = reader.result;
      const rows = csvData.split("\n");
      const headers = rows[0]
        .split(",")
        .map((header) => header.trim().toLowerCase());

      if(!checked && !headers.includes("number")) {
        toast.error("Number column is required when not auto-generating account numbers");
        return;
      }

      let jsonAccounts;

      if (alignValue === "camper") {
        if (
          !headers.includes("name") ||
          !headers.includes("bunk") ||
          !headers.includes("balance") ||
          !headers.includes("method") ||
          !headers.includes("notes")
        ) {
          toast.error(
            "Invalid CSV format for camper. Please ensure the file has columns: Name, Bunk, Balance, Method, Notes"
          );
          return;
        }

        jsonAccounts = rows.slice(1).map((row) => {
          const values = row.split(",");
          return {
            name: values[headers.indexOf("name")],
            bunk: values[headers.indexOf("bunk")],
            balance: parseFloat(
              values[headers.indexOf("balance")].replace("$", "")
            ),
            method: values[headers.indexOf("method")],
            notes: values[headers.indexOf("notes")],
            accountNo: checked ? null: values[headers.indexOf("number")],
            accountType: "Camper",
          };
        });
      } else if (alignValue === "cost" || alignValue === "staff") {
        if (
          !headers.includes("name") ||
          !headers.includes("spending limit") ||
          !headers.includes("notes")
        ) {
          toast.error(
            "Invalid CSV format for cost/staff. Please ensure the file has columns: Name, Spending Limit, Notes"
          );
          return;
        }

        jsonAccounts = rows.slice(1).map((row) => {
          const values = row.split(",");
          return {
            name: values[headers.indexOf("name")],
            spendingLimit: parseFloat(
              values[headers.indexOf("spending limit")].replace("$", "")
            ),
            notes: values[headers.indexOf("notes")],
            accountNo: checked ? null: values[headers.indexOf("number")],
            accountType: alignValue === "cost" ? "Cost" : "Staff",
          };
        });
      } else {
        toast.error("Invalid account type selected");
        return;
      }

      if(!checked) {
        const missingAccountNo = jsonAccounts.some(account => !account.accountNo);
        if(missingAccountNo) {
          toast.error("Account number is required for all accounts");
          return;
        }
      }

      setAccounts(jsonAccounts);
    };

    setFile(file);

    reader.readAsText(file);
  };

  const handleCancel = () => {
    props.handleClose();
  };

  // useEffect(() => {
  //   if (accounts.length > 0) {
  //     setAccounts(
  //       accounts.map((account, index) => ({
  //         ...account,
  //         // autoGenerateNo: checked ? AutoGenNumber() : null,
  //       }))
  //     );
  //   }
  // }, [checked]);

  const handleUpload = async () => {
    if (!file || accounts.length === 0) {
      toast.error("Please select a file to upload");
      return;
    }

    try {
      toast.loading("Uploading accounts...");
      const payload = { userId: props.userId, accounts, autoGenerateNo: checked};
      const res = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/bulkUpload`,
        payload,
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      if (res && res.data && res.data.success) {
        toast.dismiss();
        toast.success(res.data.message);
        props.handleUpload(res.data.data);
      } else {
        toast.dismiss();
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.message);
    }

    props.handleClose();
  };

  return (
    <div className="p-[20px]">
      <div
        className="font-bold"
        style={{ fontSize: "16px", lineHeight: "24px", color: "#12141E" }}
      >
        Bulk Account Upload
      </div>

      <div className="flex flex-col gap-[20px]">
        <div className="w-[100%] flex flex-col justify-center items-center">
          <div className="font-medium text-[14px] leading-[21px] text-center text-[#12141E]">
            Sample Upload
          </div>
          <div className="font-normal text-[14px] leading-[21px] text-center text-[#12141E]">
            (the first row should have the Name . . . as described below. if a
            square is blank that account will be missing the information of that
            square. save the sheet as a (CSV? Something else?))
          </div>
          <div className="font-medium text-[14px] leading-[21px] text-center text-[#12141E]">
            Select the account type you would like to upload
          </div>
        </div>

        <div className="flex flex-col items-center justify-center ">
          <Segmented
            defaultValue="camper"
            value={alignValue}
            style={{
              marginBottom: 8,
            }}
            onChange={(value) => setAlignValue(value)}
            options={tabType.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            className="custom-segmented-tab"
          />
        </div>

        {alignValue && (
          <div className="flex flex-col items-center justify-center gap-2">
            <div className="flex items-center justify-center rounded-[5px] overflow-hidden">
              <img
                src={tabType.find((type) => type.value === alignValue)[checked ? "src" : "srcWithNo"]}
                alt={alignValue}
                className="object-cover w-[100%]"
              />
            </div>
            <input type="file" onChange={handleFileChange} />
          </div>
        )}

        <div className="flex flex-row items-center justify-center gap-[16px]">
          <input
            type="checkbox"
            checked={checked}
            onClick={() => setChecked(!checked)}
            className="cursor-pointer"
          />
          <div className="font-medium text-[14px] leading-[21px] text-center text-[#12141E]">
            Auto Generate Account Numbers
          </div>
        </div>

        <div className="d-flex justify-center items-center gap-[11px] mt-3">
          <button
            className="h-[30px] w-[94px]"
            style={{
              backgroundColor: "#E0E0E0",
              borderRadius: "5px",
              color: "#000000",
            }}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="h-[30px] w-[94px]"
            style={{
              backgroundColor: "#E06F2C",
              borderRadius: "5px",
              color: "#fff",
            }}
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkUploadModalcontent;
