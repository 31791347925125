import React, { useState, useEffect, useContext } from "react";
import DotDropdown from "../ProductDetails/DotDropdown";
import SortByList from "../allOrders/SortByList";
import StatusChip from "./statusChip";
import { SortByItems } from "../../data/allOrderTableData";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import TripleDot from "../../assets/TripleDot.svg";
import { Tooltip } from "@chakra-ui/react";
import { AuthContext } from "../../auth/AuthProvider";
import { getOrderedItems, getProductNames } from "../../utils";
import { formatDate, convertToDate } from "../../utils";
import { Spinner } from "@chakra-ui/react";

function TransactionsTable() {
  const [sortingType, setSortingType] = useState("Newest");
  const {
    user,
    token,
    orders,
    setOrders,
    currentPage,
    setCurrentPage,
    totalPages,
    totalOrders,
    ordersWithPagination,
    loading,
  } = useContext(AuthContext);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const ordersPerPage = 20;

  const sampleData = [
    {
      name: "John Doe",
      contact: {
        email: "jdowan@gmail.com",
        phone: "+1 123 456 789",
      },
      amount: 150.0,
      method: {
        cardName: "American Express",
        cardNumber: "**** ***** *1234",
      },
      date_time: "Fri Mar 15, 11:26AM",
      status: "approved",
    },
    {
      name: "Jane Smith",
      contact: {
        email: "jdowan@gmail.com",
        phone: "+1 123 456 789",
      },
      amount: 200.0,
      method: {
        cardName: "American Express",
        cardNumber: "**** ***** *1234",
      },
      date_time: "Fri Mar 15, 11:26AM",
      status: "Pending",
    },
    {
      name: "Alex Johnson",
      contact: {
        email: "jdowan@gmail.com",
        phone: "+1 123 456 789",
      },
      amount: 75.0,
      method: {
        cardName: "American Express",
        cardNumber: "**** ***** *1234",
      },
      date_time: "Fri Mar 15, 11:26AM",
      status: "Failed",
    },
    {
      name: "Emily Davis",
      contact: {
        email: "jdowan@gmail.com",
        phone: "+1 123 456 789",
      },
      amount: 120.0,
      method: {
        cardName: "American Express",
        cardNumber: "**** ***** *1234",
      },
      date_time: "Fri Mar 15, 11:26AM",
      status: "approved",
    },
    {
      name: "Michael Brown",
      contact: {
        email: "jdowan@gmail.com",
        phone: "+1 123 456 789",
      },
      amount: 90.0,
      method: {
        cardName: "American Express",
        cardNumber: "**** ***** *1234",
      },
      date_time: "Fri Mar 15, 11:26AM",
      status: "Completed",
    },
  ];

  function calculate103Percent(amount) {
    return amount * 1.03;
  }

  const currentOrders = filteredOrders;

  const paginate = async (pageNumber) => {
    setOrders([]);
    await ordersWithPagination(pageNumber, ordersPerPage);
    setCurrentPage(pageNumber);
  };

  const handleCancelOrder = (orderId) => {
    setOrders((prevData) =>
      prevData.filter((order) => order.orderId !== orderId)
    );
  };

  const headingStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    backgroundColor: "#f8f8f8",
    verticalAlign: "middle",
    position: "sticky",
    top: "0px",
  };

  const sortData = (type, data) => {
    if (type === "Camper" || type === "Cost" || type === "Staff") {
      return data.filter((item) => item.accountType === type);
    } else if (type === "Cash" || type === "Account") {
      return data.filter((item) => item.method === type);
    } else {
      const sortedData = [...data].sort((a, b) => {
        if (type === "Newest") {
          return (
            new Date(b.orderedTime.seconds) - new Date(a.orderedTime.seconds)
          );
        } else if (type === "Oldest") {
          return (
            new Date(a.orderedTime.seconds) - new Date(b.orderedTime.seconds)
          );
        }
        return 0;
      });

      return sortedData;
    }
  };

  const handleSortbyChange = (selectedType) => {
    setSortingType(selectedType);
  };

  useEffect(() => {
    if (filteredOrders) {
      setFilteredOrders(sortData(sortingType, orders));
    } else {
      setFilteredOrders(orders);
    }
  }, [sortingType, orders]);

  return (
    <>
      <table className="table">
        <colgroup>
          <col style={{ width: "18%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "8%" }} />
          <col style={{ width: "1%" }} />

        </colgroup>
        <thead>
          <tr>
            <th style={{ ...headingStyle, borderTopLeftRadius: "10px" }}>
              Name
            </th>
            <th style={{ ...headingStyle }}>Contact</th>
            <th style={{ ...headingStyle }}>Amount</th>
            <th style={{ ...headingStyle }}>Method</th>
            <th style={{ ...headingStyle }}>D&T</th>
            <th style={{ ...headingStyle }}>Status</th>
            <th style={{ ...headingStyle, borderTopRightRadius: "10px" }}>
              {<SortByList items={SortByItems} onSelect={handleSortbyChange} />}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr style={{ border: "none" }}>
              <td
                colSpan="100%"
                style={{ height: "400px", border: "none" }}
                className="text-center align-middle"
              >
                <Spinner />
              </td>
            </tr>
          ) : (
            sampleData.map((item, index) => (
              <tr key={index} style={{ height: "60px" }}>
                <td style={{ verticalAlign: "middle" }}>{item.name}</td>
                <td style={{ verticalAlign: "middle" }}>
                  <div className="font-bold">{item.contact.email}</div>
                  <div>{item.contact.phone}</div>
                </td>
                <td className="ms-[-10px]" style={{ verticalAlign: "middle" }}>
                  <div>
                    <span className="font-bold text-sm">
                      ${calculate103Percent(item.amount).toFixed(2)}
                    </span>
                    (${item.amount})
                  </div>
                </td>

                <td style={{ verticalAlign: "middle" }}>
                    <div>{item.method.cardName}</div>
                    <div className="font-bold">{item.method.cardNumber}</div>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  {/* {formatDate(
                    convertToDate(
                      (item?.orderedTime).nanoseconds,
                      (item?.orderedTime).seconds
                    )
                  )} */}
                  {item.date_time}
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <StatusChip status={item.status} />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <span className="d-flex items-center ms-[30px]">
                    {/* <DotDropdown
                    //   accountId={item?.accountId}
                    //   userId={user.id}
                    //   token={token}
                    //   orderId={item.orderId}
                    //   onCancel={handleCancelOrder}
                    //   goToAccount={true}
                    /> */}
                    <img src={TripleDot} alt="dots" />
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {/* {totalOrders > ordersPerPage && orders.length ? (
        <div className="pagination d-flex justify-content-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="custom-button me-2"
          >
            Previous
          </button>
          <span className="align-self-center mx-2">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="custom-button ms-2"
          >
            Next
          </button>
        </div>
      ) : (
        ""
      )} */}
    </>
  );
}

export default TransactionsTable;
