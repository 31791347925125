import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Modal from "../Common/modal";
import FilterModalContent from "./FilterModal/filterModalContent";
import { formatBalance } from "../../utils";

const AccountsTable = ({ accountData, query, suggestions }) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [sortedAccounts, setSortedAccounts] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleOpenFilterModal = () => {
    setShowFilterModal(true);
  };

  const handleCloseFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleApplyFilterModal = () => {
    setShowFilterModal(false);
  };

  useEffect(() => {
    const handleApplyFilterModal = () => {
      const newSortOrder =
        appliedFilters[1] === "A - Z"
          ? "asc"
          : appliedFilters[1] === "Z- A"
          ? "desc"
          : "desc";
      setSortOrder(newSortOrder);

      setSortedAccounts(
        filterAndSortAccounts(
          accountData,
          appliedFilters[0],
          appliedFilters[1],
          newSortOrder,
          appliedFilters[2]
        )
      );
      setShowFilterModal(false);
    };

    handleApplyFilterModal();
  }, [appliedFilters[1], appliedFilters[2], appliedFilters[0]]);

  const navigate = useNavigate();
  const columns = [
    "Name",
    "Number",
    "Type",
    "Balance",
    "Sales",
    "Profits",
    "Orders",
    "Items",
    "Filter",
  ];

  const handleViewFull = (accountId) => {
    navigate(`/accounts/account-details/${accountId}`);
  };

  const boldStyle = {
    fontWeight: "600",
    fontSize: "16.5px",
    lineHeight: "24.75px",
    color: "rgba(18, 20, 30, 1)",
    backgroundColor: "#F8F8F8",
  };

  const normalStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(18, 20, 30, 1)",
    height: "58px",
    verticalAlign: "middle",
  };

  const balanceStyle = {
    borderRadius: "6px",
    backgroundColor: "#E06F2C",
    color: "#FFFFFF",
    padding: "2px 10px",
    border: "1.5px solid",
    gap: "10px",
  };

  const sortStyle = {
    lineHeight: "21px",
    borderRadius: "6px",
    backgroundColor: "transparent",
    color: "#12141E",
    padding: "2px 12px",
    border: "1.5px solid #EAEAEA",
    gap: "10px",
  };

  function filterAndSortAccounts(
    accounts,
    selectedTypes = [],
    sortBy = null,
    sortOrder,
    bunkNumbers = []
  ) {
    let filteredAccounts = accounts?.filter((account) => {
      const typeMatch = selectedTypes.includes(account.accountType);

      if (
        typeMatch &&
        account.accountType === "Camper" &&
        bunkNumbers.length > 0
      ) {
        return bunkNumbers?.includes(account.bunk);
      }

      return typeMatch;
    });

    if (appliedFilters?.length > 0 && appliedFilters[0]?.length === 0) {
      filteredAccounts = accounts;
    }

    if (sortBy) {
      const sortKey =
        sortBy === "A - Z" ? "name" : sortBy === "Z- A" ? "name" : "sales";
      const sortOrder =
        sortBy === "A - Z" ? "asc" : sortBy === "Z- A" ? "desc" : "desc";

      filteredAccounts?.sort((a, b) => {
        if (sortKey === "sales") {
          return sortOrder === "desc"
            ? b[sortKey] - a[sortKey]
            : a[sortKey] - b[sortKey];
        } else {
          const sortValueA = a[sortKey].toUpperCase();
          const sortValueB = b[sortKey].toUpperCase();

          if (sortValueA < sortValueB) return sortOrder === "asc" ? -1 : 1;
          if (sortValueA > sortValueB) return sortOrder === "asc" ? 1 : -1;
        }

        return 0;
      });
    }

    return filteredAccounts;
  }

  return (
    <>
      <table className="table">
        <colgroup>
          <col style={{ width: "13%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
          <col style={{ width: "5%" }} />
        </colgroup>
        <thead>
          <tr
            style={{ position: "sticky", top: "0px", verticalAlign: "middle" }}
          >
            {columns.map((column, index) => (
              <th
                key={index}
                style={{
                  ...boldStyle,
                  borderRadius:
                    index === 0
                      ? "10px 0 0 0"
                      : index === columns?.length - 1
                      ? "0 10px 0 0"
                      : "0",
                  paddingLeft: index === 0 ? "24px" : "0",
                }}
              >
                {index === 8 ? (
                  <div
                    className="d-flex flex-row items-center gap-[15px] hover:cursor-pointer"
                    style={boldStyle}
                    onClick={handleOpenFilterModal}
                  >
                    Filter
                    <MdOutlineKeyboardArrowDown className="w-6 h-6 text-gray-950" />
                  </div>
                ) : (
                  column
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(query?.length > 0
            ? suggestions
            : sortedAccounts?.length > 0
            ? sortedAccounts
            : accountData
          ).map((row, rowIndex) => {
            const isLastRow = rowIndex === accountData.length - 1;
            return (
              <tr key={rowIndex} style={{ ...normalStyle }}>
                <td
                  style={{
                    paddingLeft: "24px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  {row.name || "A/c name"}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  {row.autoGenerateNo}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  <div className="inline-block" style={sortStyle}>
                    {row.accountType}
                  </div>
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  <div className="inline-block" style={balanceStyle}>
                    {formatBalance(row.balance) || "$0.0"}
                  </div>
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  ${parseFloat(row.sales).toFixed(2) || 0.0}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  ${parseFloat(row.profits).toFixed(2) || 0.0}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  {row.orders.length || 0}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  {row.items || 0}
                </td>
                <td
                  style={{
                    padding: "0px",
                    borderBottom: isLastRow ? "none" : "1px solid #ddd",
                  }}
                >
                  <div
                    className="inline-block hover:cursor-pointer"
                    onClick={() => handleViewFull(row.accountId)}
                    style={sortStyle}
                  >
                    View Profile
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal isVisible={showFilterModal} onClose={handleCloseFilterModal}>
        <FilterModalContent
          onClose={handleCloseFilterModal}
          onApply={handleApplyFilterModal}
          setAppliedFilters={setAppliedFilters}
          appliedFilters={appliedFilters}
        />
      </Modal>
    </>
  );
};

export default AccountsTable;
