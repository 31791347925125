import React from "react";
import CircleSelection from "../../Common/CircleSelection";

const CategoryItem = ({
  imageUrl,
  name,
  onCategoryClick,
  onDownloadSelect,
  isSelectedForCategory,
  isSelectedForDownload,
}) => {
  return (
    <div
      className={`flex-shrink-0 relative inline-block w-[109px] h-[132px] ${
        isSelectedForCategory ? "text-sienna" : ""
      }`}
      onClick={onCategoryClick}
    >
      <div className="absolute z-50 top-2 left-2">
        <CircleSelection
          size="25"
          onClick={onDownloadSelect}
          isSelected={isSelectedForDownload}
        />
      </div>
      <div
        className={`rounded-lg border-[1px] border-solid w-full h-full cursor-pointer ${
          isSelectedForCategory
            ? "bg-chocolate-300 border-[#E66E27]"
            : "bg-white border-lightgray-100"
        }`}
      >
        <img
          className="w-[51px] h-[51px] mx-auto mt-4"
          alt={name}
          src={imageUrl}
        />
        <div
          className={`text-center font-nunito mt-3 ${
            isSelectedForCategory ? "text-sienna" : "text-gray-500"
          }`}
          style={{
            color: isSelectedForCategory ? "#704332" : "",
            fontWeight: isSelectedForCategory ? 600 : "",
          }}
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
