import React, { Fragment, useState, useEffect, useContext } from "react";
import SearchBar from "./ProductsPageComponent/SearchBar";
import { Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import AccountsTable from "./AccountsPage/accountTable";
import Modal from "../components/Common/modal";
import AccountModalContent from "../components/AccountsPage/accountModalContent";
import BulkUploadModalcontent from "./AccountsPage/bulkUploadModalcontent";
import { AuthContext } from "../auth/AuthProvider";
import axios from "axios";
import toast from "react-hot-toast";
import LoadingSpinner from "./Common/loadingSpinner";
import { capitalize } from "../utils";

const Accounts = () => {
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const { user, token, accounts, setAccounts, loading } =
    useContext(AuthContext);

  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [autoGenerateNo, setAutoGenerateNo] = useState(0);

  const fetchAutoGenerateNo = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/api/pos/account/getAccountNumber`,
        { userId: user.id },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAutoGenerateNo(response.data.data.autoGenerateNo);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchAutoGenerateNo();
  }, [accounts]);

  const handleAddAccount = (newAccount) => {
    setAccounts([...accounts, newAccount]);

    setShowAccountModal(false);
  };

  const handleOpenAccountModal = () => {
    setShowAccountModal(true);
  };

  const handleCloseAccountModal = () => {
    setShowAccountModal(false);
  };

  const handleOpenBulkModal = () => {
    setShowBulkModal(true);
  };

  const handleCloseBulkModal = () => {
    setShowBulkModal(false);
  };

  const handleSeachChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value.length > 0) {
      const filteredAccounts = accounts?.filter(
        (account) =>
          account.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(value.toLowerCase().replace(/\s+/g, "")) ||
          account.autoGenerateNo.toString().includes(value)
      );
      setSuggestions(filteredAccounts);
    } else {
      setSuggestions([]);
    }
  };

  const handleUploadBulkModal = (uploadedAccounts) => {
    setAccounts([...accounts, ...uploadedAccounts]);
  };

  const downloadAccountsCSV = () => {
    const header = [
      "Account Name",
      "Account Number",
      "Account Type",
      "Balance",
      "Sales",
      "Profits",
      "Orders",
      "Items",
      "Bunk Number",
    ];

    const csvData = accounts.map((account) => [
      capitalize(account.name),
      account.autoGenerateNo,
      account.accountType,
      "$" + account.balance,
      "$" + account.sales,
      "$" + account.profits,
      account.orders.length,
      account.items,
      account?.bunk || "",
    ]);

    csvData.unshift(header);

    const csvRows = [];
    for (const row of csvData) {
      csvRows.push(row.join(","));
    }

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "accounts.csv");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Fragment>
      <div
        className="h-[100%] w-[100%] px-[42px] pt-[38px] bg-white"
        style={{ borderRadius: "20px" }}
      >
        <div className="flex flex-col w-[100%] h-[100%] justify-between">
          <div className="w-[100%] h-[40px]">
            <div className="d-flex flex-row justify-between w-[100%] gap-[2%] h-[20%] min-h-[40px]">
              <div className="d-flex flex-row justify-between w-[50%]">
                <div
                  style={{
                    fontSize: "24px",
                    color: "#12141E",
                    fontWeight: "700",
                    lineHeight: "36px",
                  }}
                >
                  Accounts
                </div>

                <SearchBar
                  placeholder={"Search Accounts"}
                  onChange={handleSeachChange}
                  value={query}
                />
              </div>

              <div className="d-flex flex-row justify-between w-[48%]">
                <Button
                  variant={"outline"}
                  leftIcon={<AddIcon />}
                  colorScheme="gray"
                  onClick={handleOpenAccountModal}
                >
                  Add Account
                </Button>
                <Button
                  variant={"outline"}
                  colorScheme="gray"
                  onClick={downloadAccountsCSV}
                >
                  Download
                </Button>
                <Button
                  variant={"outline"}
                  colorScheme="gray"
                  leftIcon={<AddIcon />}
                  onClick={handleOpenBulkModal}
                >
                  bulk Upload
                </Button>
              </div>
            </div>
          </div>

          <div className="w-[100%] h-[92%] bg-white d-flex justify-center">
            <div className="w-[100%] custom-scrollbar overflow-auto">
              <AccountsTable
                accountData={accounts}
                query={query}
                suggestions={suggestions}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal isVisible={showAccountModal} onClose={handleCloseAccountModal}>
        <AccountModalContent
          onClose={handleCloseAccountModal}
          autoGenNumber={autoGenerateNo}
          addAccount={handleAddAccount}
        />
      </Modal>

      <Modal isVisible={showBulkModal} onClose={handleCloseBulkModal}>
        <BulkUploadModalcontent
          handleClose={handleCloseBulkModal}
          handleUpload={handleUploadBulkModal}
          token={token}
          userId={user.id}
        />
      </Modal>

      <LoadingSpinner isLoading={loading} />
    </Fragment>
  );
};

export default Accounts;
