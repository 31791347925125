export const AutoGenNumber = () => {
  const randomNum = Math.random() * 9000;
  return Math.floor(1000 + randomNum);
};

export const getCategoryNameById = (categoryId, categories) => {
  const category = categories.find((cat) => cat.categoryId === categoryId);
  return category ? category.categoryName : null;
};

export const getAccountNameByID = (accountId, accounts) => {
  const account = accounts.find((acc) => acc.accountId === accountId);
  return account ? account.name : null;
};

export const getAccountTypeByID = (accountId, accounts) => {
  const account = accounts.find((acc) => acc.accountId === accountId);
  return account ? account.accountType : null;
};

export const getProductNames = (order) => {
  const orderData = order.orderedItems;
  const productNames = orderData.map((item) => item.name);
  const sortedProductNames = productNames.sort();
  const productNameString = sortedProductNames.join(", ");
  return productNameString;
};

export const formatDate = (date) => {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    year: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

export const convertToDate = (nanoseconds, seconds) => {
  const totalMilliseconds = seconds * 1000 + Math.floor(nanoseconds / 1000000);
  return new Date(totalMilliseconds);
};

export const getOrderedItems = (order) => {
  const orderData = order.orderedItems;
  let qty = 0;
  orderData.forEach((item) => {
    qty += item.qty;
  });
  return qty;
};

export const formatBalance = (balance) => {
  if (balance === undefined) {
    return;
  }
  return balance < 0 ? `-$${Math.abs(balance)}` : `$${balance}`;
};

export const capitalize = (str) => {
  return str
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
